@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i,700,700i');
@import url('https://rsms.me/inter/inter-ui.css');

.euiHeader {
  display: flex !important;
  padding-left: 72px !important;
  padding-right: 13px !important;
  justify-content: space-between !important;
  box-shadow: none !important;
  border: none !important;
}

.euiPopover__panel {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.euiPopover__panelArrow:after {
  content: none !important;
}

.euiPopover__panelArrow:before {
  content: none !important;
}

.euiFormControlLayout {
  border-radius: 6px !important;
}

.euiFieldText {
  border-radius: 6px !important;
}

.euiOverlayMask {
  background: rgba(39, 39, 39, .72) !important;
  padding-bottom: 6vh;
}